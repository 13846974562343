<template>
  <div>
    <div class="d-flex mt-2">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Datos personales
      </h4>
    </div>
    <b-row>
      <b-col
        cols="4"
        class="mt-2 text-center mx-auto d-flex justify-content-center"
      >
        <profile-avatar
          :name="userData.avatar"
          :loading="loading"
          :avatar="avatar"
          @input="handleSubmitAvatar"
        />
      </b-col>
      <b-col cols="8">
        <validation-observer ref="basic_data">
          <b-form
            autocomplete="off"
            @submit.prevent="handleSubmitUpdateAccount"
          >
            <b-row class="w-100 m-0">
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="User"
                  rules="required|alpha_spaces"
                >
                  <b-form-group label="Full Name *">
                    <b-form-input
                      v-model="userData.name"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                    />
                    <b-form-invalid-feedback
                      v-if="errors"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-group label="Email *">
                    <b-form-input
                      v-model="userData.email"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                    />
                    <b-form-invalid-feedback
                      v-if="errors"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone number"
                  rules="phone"
                >
                  <b-form-group label="Phone number">
                    <b-form-input
                      v-model="userData.phone"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                    />
                    <b-form-invalid-feedback
                      v-if="errors"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="4"
                offset="8"
                class="mt-2"
              >
                <b-button
                  type="submit"
                  block
                  variant="primary"
                  class="common-buttons"
                >
                  Save
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ProfileAvatar from '@core/components/profile/ProfileAvatar.vue'

import {
  phone, mayus, minus, num, charesp,
} from '@validations'

export default {
  name: 'TabPersonal',
  components: {
    ProfileAvatar,
  },
  data() {
    return {
      loading: false,
      userData: {
        avatar: '',
        name: '',
        phone: '',
        email: '',
      },
    }
  },
  computed: {

  },
  methods: {
    uploadImage(event) {
      if (event == null) { this.previewImage = null; this.userData.image = null; this.$refs.refInputEl.value = null; return }
      this.userData.image = event.target.files[0]
      const reader = new FileReader()
      reader.onload = event => { this.previewImage = reader.result }
      reader.readAsDataURL(this.userData.image)
      this.$refs.refInputEl.value = null
    },
    update() {
      const formData = new FormData()
      formData.append('id', this.userData.id)
      formData.append('name', this.userData.name)
      formData.append('email', this.userData.email)
      if (this.userData.image != null) {
        formData.append('image', this.userData.image)
        formData.append('original_image_name', this.userData.original_image_name)
      }
      this.$store.dispatch('appUsers/updateUser', formData).then(response => {
        this.$store.commit('appAuth/SET_USER_FRESH', response.data.data)
        this.previewImage = null
        this.$handleSuccessResponse(response)
      }).catch(error => {
        this.$handleErrorResponse(error)
      })
    },
  },
}
</script>

<style lang="scss">
    .profile-head {
        text-align: center;
    }
</style>
