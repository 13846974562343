<template>
  <div>
    <div class="d-flex mt-2">
      <feather-icon
        icon="LockIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Cambio de Contraseña
      </h4>
    </div>
    <validation-observer ref="security">
      <b-form
        autocomplete="off"
        @submit.prevent="handleSubmitSecurity"
      >
        <b-row>
          <b-col
            cols="12"
            class="mt-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Current Password"
            >
              <b-form-group
                label="Current Password:"
                label-for="s-current-password"
              >
                <b-form-input
                  id="s-current-password"
                  ref="password"
                  v-model="security.current_password"
                  placeholder="********"
                  required
                  :state="errors.length == 0"
                  type="password"
                  autocomplete="current-password"
                />
                <b-form-invalid-feedback
                  v-if="errors"
                  v-text="errors[0]"
                />
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="New Password"
              :rules="{
                required: security.current_password ? true : false,
                min: 8,
                mayus: 1,
                minus: 1,
                num: 1,
                charesp: 1
              }"
            >
              <b-form-group
                label="New Password:"
                label-for="s-new-password"
              >
                <b-form-input
                  id="s-new-password"
                  v-model="security.password"
                  required
                  :state="errors.length == 0"
                  type="password"
                  :disabled="!security.current_password"
                  autocomplete="new-password"
                />
                <b-form-invalid-feedback
                  v-if="errors"
                  v-text="errors[0]"
                />
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="Password Confirmation"
              :rules="{
                required: security.current_password || security.password ? true : false,
                confirmed: 'New Password'
              }"
            >
              <b-form-group
                label="Password Confirmation:"
                label-for="s-confirm-password"
              >
                <b-form-input
                  id="s-confirm-password"
                  v-model="security.password_confirmation"
                  required
                  :state="errors.length == 0"
                  type="password"
                  :disabled="!security.password"
                  autocomplete="new-password"
                />

                <b-form-invalid-feedback
                  v-if="errors"
                  v-text="errors[0]"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="2"
            offset="10"
            class="mt-2 text-right"
          >
            <b-button
              type="submit"
              :disabled="security.loading"
              block
              variant="primary"
              class="common-buttons"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
// eslint-disable-next-line import/extensions
import es from 'vee-validate/dist/locale/es'
import * as validations from 'vee-validate/dist/rules'
import {
  mayus, minus, num, charesp,
} from '@/libs/custom-rules'

const rules = {
  ...validations,
  mayus,
  minus,
  num,
  charesp,
}

localize('es', es)
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      security: {
        password: null,
        new_password: null,
        confirm_password: null,
      },
    }
  },
  methods: {
    handleSubmitSecurity() {
      this.$refs.security.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('current_password', this.security.password)
          formData.append('new_password', this.security.new_password)
          formData.append('repeat_new_password', this.security.confirm_password)
          this.$store.dispatch('appAuth/changePassword', formData).then(response => {
            this.security = {}
            this.$refs.security.reset()
            this.$handleSuccessResponse(response)
          }).catch(error => {
            this.$handleErrorResponse(error)
          })
        }
      })
    },
  },
}
</script>
