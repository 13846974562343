<template>
  <b-card>
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Personal</span>
        </template>
        <tab-personal />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Seguridad</span>
        </template>
        <tab-security />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="BellIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Notificaciones</span>
        </template>
        <tab-notifications />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import TabPersonal from './TabPersonal.vue'
import TabSecurity from './TabSecurity.vue'
import TabNotifications from './TabNotifications.vue'

export default {
  components: {
    TabNotifications,
    TabSecurity,
    TabPersonal,
  },
}
</script>
